import flag from "./FlagImg";

export const CountryFlag = (country) => {
    switch (country) {
        case 'AL':
            return flag.AL;
        case 'DZ':
            return flag.DZ;
        case 'AO':
            return flag.AO;
        case 'AR':
            return flag.AR;
        case 'AM':
            return flag.AM;
        case 'AU':
            return flag.AU;
        case 'AT':
            return flag.AT;
        case 'AZ':
            return flag.AZ;
        case 'BS':
            return flag.BS;
        case 'BD':
            return flag.BD;
        case 'BB':
            return flag.BB;
        case 'BY':
            return flag.BY;
        case 'BE':
            return flag.BE;
        case 'BT':
            return flag.BT;
        case 'BO':
            return flag.BO;
        case 'BR':
            return flag.BR;
        case 'BG':
            return flag.BG;
        case 'KH':
            return flag.KH;
        case 'CM':
            return flag.CM;
        case 'CA':
            return flag.CA;
        case 'TD':
            return flag.TD;
        case 'CL':
            return flag.CL;
        case 'CN':
            return flag.CN;
        case 'CO':
            return flag.CO;
        case 'CU':
            return flag.CU;
        case 'CZ':
            return flag.CZ;
        case 'DK':
            return flag.DK;
        case 'EG':
            return flag.EG;
        case 'GB_EN':
            return flag.GB_EN;
        case 'EE':
            return flag.EE;
        case 'ET':
            return flag.ET;
        case 'FJ':
            return flag.FJ;
        case 'FI':
            return flag.FI;
        case 'FR':
            return flag.FR;
        case 'GE':
            return flag.GE;
        case 'DE':
            return flag.DE;
        case 'GH':
            return flag.GH;
        case 'GR':
            return flag.GR;
        case 'GL':
            return flag.GL;
        case 'GT':
            return flag.GT;
        case 'GN':
            return flag.GN;
        case 'HT':
            return flag.HT;
        case 'HK':
            return flag.HK;
        case 'HU':
            return flag.HU;
        case 'IS':
            return flag.IS;
        case 'IN':
            return flag.IN;
        case 'ID':
            return flag.ID;
        case 'IR':
            return flag.IR;
        case 'IQ':
            return flag.IQ;
        case 'IE':
            return flag.IE;
        case 'IL':
            return flag.IL;
        case 'IT':
            return flag.IT;
        case 'JP':
            return flag.JP;
        case 'JO':
            return flag.JO;
        case 'KZ':
            return flag.KZ;
        case 'KE':
            return flag.KE;
        case 'KG':
            return flag.KG;
        case 'KR':
            return flag.KR;
        case 'LB':
            return flag.LB;
        case 'LR':
            return flag.LR;
        case 'LY':
            return flag.LY;
        case 'LT':
            return flag.LT;
        case 'MG':
            return flag.MG;
        case 'MY':
            return flag.MY;
        case 'ML':
            return flag.ML;
        case 'MX':
            return flag.MX;
        case 'MD':
            return flag.MD;
        case 'MC':
            return flag.MC;
        case 'MN':
            return flag.MN;
        case 'MA':
            return flag.MA;
        case 'MM':
            return flag.MM;
        case 'NP':
            return flag.NP;
        case 'NL':
            return flag.NL;
        case 'NZ':
            return flag.NZ;
        case 'NG':
            return flag.NG;
        case 'NO':
            return flag.NO;
        case 'OM':
            return flag.OM;
        case 'PK':
            return flag.PK;
        case 'PA':
            return flag.PA;
        case 'PY':
            return flag.PY;
        case 'PE':
            return flag.PE;
        case 'PH':
            return flag.PH;
        case 'PL':
            return flag.PL;
        case 'PT':
            return flag.PT;
        case 'QA':
            return flag.QA;
        case 'RO':
            return flag.RO;
        case 'RU':
            return flag.RU;
        case 'SA':
            return flag.SA;
        case 'RS':
            return flag.RS;
        case 'SG':
            return flag.SG;
        case 'SK':
            return flag.SK;
        case 'SO':
            return flag.SO;
        case 'ZA':
            return flag.ZA;
        case 'ES':
            return flag.ES;
        case 'LK':
            return flag.LK;
        case 'SD':
            return flag.SD;
        case 'SE':
            return flag.SE;
        case 'CH':
            return flag.CH;
        case 'SY':
            return flag.SY;
        case 'TW':
            return flag.TW;
        case 'TJ':
            return flag.TJ;
        case 'TZ':
            return flag.TZ;
        case 'TH':
            return flag.TH;
        case 'TR':
            return flag.TR;
        case 'TM':
            return flag.TM;
        case 'UG':
            return flag.UG;
        case 'UA':
            return flag.UA;
        case 'AE':
            return flag.AE;
        case 'GB_UK':
            return flag.GB_UK;
        case 'US':
            return flag.US;
        case 'UZ':
            return flag.UZ;
        case 'VE':
            return flag.VE;
        case 'VN':
            return flag.VN;
        case 'YE':
            return flag.YE;
        case 'ZM':
            return flag.ZM;
        case 'ZW':
            return flag.ZW;
        default:
            return flag.default;
    }
}