const flag = {
    default: require('../../img/cCountry.png'),
    AL: require('../../img/flags/albania.png'),
    DZ: require('../../img/flags/algeria.png'),
    AO: require('../../img/flags/angola.png'),
    AR: require('../../img/flags/argentina.png'),
    AM: require('../../img/flags/armenia.png'),
    AU: require('../../img/flags/australia.png'),
    AT: require('../../img/flags/austria.png'),
    AZ: require('../../img/flags/azerbaijan.png'),
    BS: require('../../img/flags/bahamas.png'),
    BD: require('../../img/flags/bangladesh.png'),
    BB: require('../../img/flags/barbados.png'),
    BY: require('../../img/flags/belarus.png'),
    BE: require('../../img/flags/belgium.png'),
    BT: require('../../img/flags/bhutan.png'),
    BO: require('../../img/flags/bolivia.png'),
    BR: require('../../img/flags/brazil.png'),
    BG: require('../../img/flags/bulgaria.png'),
    KH: require('../../img/flags/cambodia.png'),
    CM: require('../../img/flags/cameroon.png'),
    CA: require('../../img/flags/canada.png'),
    TD: require('../../img/flags/chad.png'),
    CL: require('../../img/flags/chile.png'),
    CN: require('../../img/flags/china.png'),
    CO: require('../../img/flags/colombia.png'),
    CU: require('../../img/flags/cuba.png'),
    CZ: require('../../img/flags/czech-republic.png'),
    DK: require('../../img/flags/denmark.png'),
    EG: require('../../img/flags/egypt.png'),
    GB_EN: require('../../img/flags/england.png'),
    EE: require('../../img/flags/estonia.png'),
    ET: require('../../img/flags/ethiopia.png'),
    FJ: require('../../img/flags/fiji.png'),
    FI: require('../../img/flags/finland.png'),
    FR: require('../../img/flags/france.png'),
    GE: require('../../img/flags/georgia.png'),
    DE: require('../../img/flags/germany.png'),
    GH: require('../../img/flags/ghana.png'),
    GR: require('../../img/flags/greece.png'),
    GL: require('../../img/flags/greenland.png'),
    GT: require('../../img/flags/guatemala.png'),
    GN: require('../../img/flags/guinea.png'),
    HT: require('../../img/flags/haiti.png'),
    HK: require('../../img/flags/hong-kong.png'),
    HU: require('../../img/flags/hungary.png'),
    IS: require('../../img/flags/iceland.png'),
    IN: require('../../img/flags/india.png'),
    ID: require('../../img/flags/indonesia.png'),
    IR: require('../../img/flags/iran.png'),
    IQ: require('../../img/flags/iraq.png'),
    IE: require('../../img/flags/ireland.png'),
    IL: require('../../img/flags/israel.png'),
    IT: require('../../img/flags/italy.png'),
    JP: require('../../img/flags/japan.png'),
    JO: require('../../img/flags/jordan.png'),
    KZ: require('../../img/flags/kazakhstan.png'),
    KE: require('../../img/flags/kenya.png'),
    KG: require('../../img/flags/kyrgyzstan.png'),
    LB: require('../../img/flags/lebanon.png'),
    LR: require('../../img/flags/liberia.png'),
    LY: require('../../img/flags/libya.png'),
    LT: require('../../img/flags/lithuania.png'),
    MG: require('../../img/flags/madagascar.png'),
    MY: require('../../img/flags/malaysia.png'),
    ML: require('../../img/flags/mali.png'),
    MX: require('../../img/flags/mexico.png'),
    MD: require('../../img/flags/moldova.png'),
    MC: require('../../img/flags/monaco.png'),
    MN: require('../../img/flags/mongolia.png'),
    MA: require('../../img/flags/morocco.png'),
    MM: require('../../img/flags/myanmar.png'),
    NP: require('../../img/flags/nepal.png'),
    NL: require('../../img/flags/netherlands.png'),
    NZ: require('../../img/flags/new-zealand.png'),
    NG: require('../../img/flags/nigeria.png'),
    NO: require('../../img/flags/norway.png'),
    OM: require('../../img/flags/oman.png'),
    PK: require('../../img/flags/pakistan.png'),
    PA: require('../../img/flags/panama.png'),
    PY: require('../../img/flags/paraguay.png'),
    PE: require('../../img/flags/peru.png'),
    PH: require('../../img/flags/philippines.png'),
    PL: require('../../img/flags/poland.png'),
    PT: require('../../img/flags/portugal.png'),
    QA: require('../../img/flags/qatar.png'),
    RO: require('../../img/flags/romania.png'),
    RU: require('../../img/flags/russia.png'),
    SA: require('../../img/flags/saudi-arabia.png'),
    RS: require('../../img/flags/serbia.png'),
    SG: require('../../img/flags/singapore.png'),
    SK: require('../../img/flags/slovakia.png'),
    SO: require('../../img/flags/somalia.png'),
    ZA: require('../../img/flags/south-africa.png'),
    KR: require('../../img/flags/south-korea.png'),
    ES: require('../../img/flags/spain.png'),
    LK: require('../../img/flags/sri-lanka.png'),
    SD: require('../../img/flags/sudan.png'),
    SE: require('../../img/flags/sweden.png'),
    CH: require('../../img/flags/switzerland.png'),
    SY: require('../../img/flags/syria.png'),
    TW: require('../../img/flags/taiwan.png'),
    TJ: require('../../img/flags/tajikistan.png'),
    TZ: require('../../img/flags/tanzania.png'),
    TH: require('../../img/flags/thailand.png'),
    TR: require('../../img/flags/turkey.png'),
    TM: require('../../img/flags/turkmenistan.png'),
    UG: require('../../img/flags/uganda.png'),
    UA: require('../../img/flags/ukraine.png'),
    AE: require('../../img/flags/united-arab-emirates.png'),
    GB_UK: require('../../img/flags/united-kingdom.png'),
    US: require('../../img/flags/united-states.png'),
    UZ: require('../../img/flags/uzbekistan.png'),
    VE: require('../../img/flags/venezuela.png'),
    VN: require('../../img/flags/vietnam.png'),
    YE: require('../../img/flags/yemen.png'),
    ZM: require('../../img/flags/zambia.png'),
    ZW: require('../../img/flags/zimbabwe.png'),
}

export default flag;